import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/LayoutComponent"
import Seo from "../components/Seo"

const MusicTherapy = () => (
  <Layout>
    <Seo title="Music Therapy" />
    <div>
      <p>
        The Canadian Association of Music Therapists defines Music Therapy as:
        “... a discipline in which credentialed professionals (MTA*) use music
        purposefully within therapeutic relationships to support development,
        health, and well-being. Music therapists use music safely and ethically
        to address human needs within cognitive, communicative, emotional,
        musical, physical, social, and spiritual domains.”
      </p>
      <p>
        Music Therapists work in a wide variety of settings, including
        hospitals, long-term care facilities, mental health & addictions centers
        and hospices. They work with diverse client populations, including
        people living with autism and other developmental disabilities and even
        healthy adults wanting to explore music therapy as a means for personal
        growth.
      </p>
      <p>
        To date most of my clinical experience has been in long-term care
        facilities, with 3 years of experience in that setting, working with
        individuals with dementia and other diseases of aging. I also have
        experience working with children with developmental disabilities and
        behavioural disorders and otherwise at-risk children, youth mental
        health and addictions, and grief and bereavement support.
      </p>
    </div>
  </Layout>
)

export default MusicTherapy
